<script>
//   import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{},
    props:['service','ODS','send','pos','estimated','disable','planB','period'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedMaterials:[],
            selectedMTR:[],
            selectedReal:[],
            ProductList:[
                engrais=>[
                    {name:"NPK ",unite:"QX",Qte:""},
                    {name:"Urée 46 %",unite:"QX",Qte:""},
                    {name:"TSP",unite:"QX",Qte:""},
                    {name:"Engrais pour pantes d’intérieurs",unite:"L",Qte:""},
                    {name:"Lustrant plante d’intérieur",unite:"B",Qte:""},
                    {name:"Engrais liquide NADI",unite:"L",Qte:""},
                ],
                plantes=>[
                    {name:"Arbre Forestier (âgés de 02 ans)",unite:"P",Qte:""},
                    {name:"Arbre ornementales & arbustes(sujet moyen)",unite:"P",Qte:""},
                    {name:"Arbre ornementales & arbustes (grand sujet 1.5 m)",unite:"P",Qte:""},
                    {name:"Arbre fruitier (âgés de 3ans)",unite:"P",Qte:""},
                    {name:"Palmiers (âgés de 05 ans)",unite:"P",Qte:""},
                    {name:"Gazon en plaque (m2)",unite:"m²",Qte:""},
                ],
                engrais=>[{name:"Arbre ornementales & arbustes(sujet moyen)",unite:"P",Qte:""}],
            ],
            RH:[],
            postes:[],
            EquipmentsList:[],
            EQPListOrdering:[],
            retrieveEQP:false

        }
    },
    mounted(){
        
        // if(this.service[this.pos][0].quantity > 0){
            switch (this.pos) {
                case 'Entretien':
                    var keys = Object.keys(this.service[this.pos]);
                    keys.forEach(x => {
                        switch (x) {
                            case 'human':
                                this.fields = [];
                                this.service[this.pos][x].forEach(element => {
                                    if(element.service.estimation_type == 'daily') this.RH.push(element.service)
                                    this.fields.push({id:element.id,poste:element.service,qte:element.quantity,amount:element.amount,uom:element.uom,checkQte:element.quantity})
                                    // this.PostesList.push(element.service)
                                })
                                this.emitData()
                                break;
                            case 'equipment':
                                this.retrieveEQP=true
                                this.EquipmentsList = this.service[this.pos][x].filter(x => (x.service.estimation_type == 'daily'));

                                this.service[this.pos][x].forEach(element => {
                                    this.selectedMaterials.push({id:element.id,equipment:element.service,qte:element.quantity,amount:element.amount,uom:element.uom,checkQte:element.quantity})
                                    // this.PostesList.push(element.service)
                                })
                                 this.emitData()
                                break;
                        
                            default:
                                break;
                        }
                    })
                    
                    break;
                case 'Fournitures des produits phytosanitaires':
                    this.service[this.pos].forEach(element => {
                        this.selectedProducts = [];
                        this.selectedProducts.push({id:element.id,product:element.product,qte:element.quantity,amount:element.amount,checkQte:element.quantity})
                    })
                     this.emitData()
                    break;
                case 'Matériel roulant':
                    this.service[this.pos].forEach(element => {
                        this.selectedMTR = [];
                        this.selectedMTR.push({id:element.id,equipments:element.service,qte:element.quantity,amount:element.amount,checkQte:element.quantity,equipment:element.equipment})
                    })
                     this.emitData()
                    break;
            
                default:
                    this.service[this.pos].forEach(element => {
                        this.selectedReal = [];
                        this.selectedReal.push({id:element.id,realize:element.service,qte:element.quantity,amount:element.amount,checkQte:element.quantity})
                    })
                     this.emitData()
                    break;
            }
        // }
        // else {
        //     this.PostesList = this.service[this.pos];
        //     this.ProductsList = this.service[this.pos];
        // }
    },
    methods:{
        emitData(){
            switch (this.pos) {
                case 'Entretien':
                    this.$emit('getvalues',{human:this.fields,equipment:this.selectedMaterials})
                    break;
                case 'Fournitures des produits phytosanitaires':
                    this.$emit('getvalues',this.selectedProducts)
                    break;
                case 'Matériel roulant':
                    this.$emit('getvalues',this.selectedMTR)
                    break;
                case 'Réalisation':
                    this.$emit('getvalues',this.selectedReal)
                    break;
            
                default:
                    break;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1"  v-if="pos == 'Entretien'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                        <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Humaines (Entretien)</div>
                        <div class="col text-right">
                            <span>Nombre de Postes demandé: {{fields.length - RH.length}} </span>
                            <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                            <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                        </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">

                                <div class="row">
                                    <div class="col-lg-6">
                                    <b-card title="Personel Contractuels">
                                        <b-card-text>
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                <th class="text-center">à la demander</th>
                                                <th class="text-right">Nombres</th>
                                            </thead>

                                            <tr v-for="(poste,i) in RH" :key="i">
                                                <th> {{poste.job_title.designation}} </th>
                                                <!-- <td v-if="poste.estima" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td> -->
                                                <td class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-right"> {{poste.estimated_qty}} </td>
                                            </tr>
                                        </table>
                                        </b-card-text>
                                    </b-card>
                                    </div>
                                    <div class="col-lg-6">
                                    <b-card title="Personnels Envoyé">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                <!-- <th class="text-center">à la demander</th> -->
                                                <th class="text-right">Nombres</th>
                                                <th class="text-right">Unité</th>
                                                <th class="text-right">Salaire/Jour</th>
                                                <th class="text-right">Confirmer Nombres</th>
                                            </thead>

                                            <tr v-for="field in fields" :key="field.poste.id">
                                                <th v-if="field.poste.job_title"> {{field.poste.job_title.designation}} </th>
                                                <td class="text-center"> {{field.qte}} </td>
                                                <td class="text-center"> {{field.uom.designation}}</td>
                                                <td class="text-center"> {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(field.poste.unit_price)}} </td>
                                                <td class="text-right" style="width:33%"> <input type="number" :class="{'is-invalid':field.checkQte != field.qte}" :disabled="ODS.read_at == null" :max="field.poste.estimated_qty" class="form-control" min="0" v-model="field.checkQte"  @keypress="isNumber($event)" @input="emitData()"></td>
                                            </tr>
                                        </table>
                                        
                                    </b-card>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1"  v-if="pos == 'Entretien' && retrieveEQP==1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Materiels</div>
                            <div class="col text-right">
                                <span>Quantité Materiels à Envoyée : {{selectedMaterials.length}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-card title="Materiels Contractuels">
                                        <b-card-text>
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Materiels</th>
                                                <th class="text-center">à la demander ?</th>
                                                <th class="text-right">Quantité</th>
                                            </thead>

                                            <tr v-for="(materiel,i) in EquipmentsList" :key="i">
                                                <th> {{materiel.equipment.name}} </th>
                                                <td v-if="materiel.service.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-right"> {{materiel.service.estimated_qty}} </td>
                                            </tr>
                                        </table>
                                        </b-card-text>
                                    </b-card>
                                </div>
                                <div class="col-md-6">
                                    <b-card title="Materiels à demander">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Materiel</th>
                                                <th class="text-right">Quantité</th>
                                                <th class="text-right">Unité</th>
                                                <th class="text-right">Prix Unitaire</th>
                                                <th class="text-right">Quantité Envoyée</th>
                                            </thead>

                                            <tr v-for="equipment in selectedMaterials" :key="equipment.equipment.id">
                                                <th> {{equipment.equipment.name}} </th>
                                                <td class="text-center"> {{equipment.qte}} </td>
                                                <td class="text-center"> {{equipment.uom.designation}}</td>
                                                <td class="text-center"> {{equipment.equipment.unit_price}}</td>
                                                <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null" :class="{'is-invalid':equipment.checkQte != equipment.qte}" :max="equipment.equipment.estimated_qty" class="form-control" min="0" v-model="equipment.checkQte" @input="emitData()"  @keypress="isNumber($event)"></td>
                                            </tr>
                                        </table>
                                    </b-card>
                                </div> 
                            </div>
                                
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Fournitures des produits phytosanitaires'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Produits</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Produit</th>
                                    <th class="text-center">Délai</th>
                                    <th class="text-center">Quantité</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr v-for="product in selectedProducts" :key="product.product.id">
                                    <th> {{period}} </th>
                                    <th> {{product.product.designation}} </th>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null" :class="{'is-invalid':product.checkQte != product.qte}" class="form-control" min="0" v-model="product.checkQte" @input="emitData()"  @keypress="isNumber($event)"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Matériel roulant'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Matériel roulant</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Matériel</th>
                                    <th class="text-center">à la demander</th>
                                    <th class="text-center">Quantité</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr v-for="product in selectedMTR" :key="product.equipments.id">
                                    <th> {{product.equipment.name}} </th>
                                    <td v-if="product.equipments.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                    <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null" :class="{'is-invalid':product.checkQte != product.qte}" class="form-control" min="0" v-model="product.checkQte" @input="emitData()"  @keypress="isNumber($event)"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1" v-if="pos == 'Réalisation'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Réalisation</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Réalisation</th>
                                    <th class="text-center">Délai</th>
                                    <th class="text-center">Quantité</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr v-for="product in selectedReal" :key="product.realize.id">
                                    <th> {{product.realize.name}} </th>
                                    <th> {{period}} </th>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null" :class="{'is-invalid':product.checkQte != product.qte}" class="form-control" min="0" v-model="product.checkQte" @input="emitData()"  @keypress="isNumber($event)"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>