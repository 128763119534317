<script>
    import Layout from "../../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import ODSform from "./components/ODSform";
    import Dataset from 'vue-dataset/dist/es/Dataset.js'
    import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
    // import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
    import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
    // import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
    import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
    
    export default {
        page: {
            title: "Base de vies de l'entreprise",
            meta: [{ name: "description", content: appConfig.description}],
        },
        // import : { fetchSettings },
        components: {
          Layout, PageHeader,ODSform,
          Dataset,
          DatasetItem,
          // DatasetInfo,
          DatasetPager,
          // DatasetSearch,
          DatasetShow,
        },
        data() {
    
                return {
          tableData: [],
          title: "Reception",
          List_ODS: [],
          rows:'',
          active:false,
          selectedRows:[],
          ODS:undefined,
          breakfast:[],
          lunch:[],
          dinner:[],
          items: [
            {
              text: "Catalogue des Services",
              to: {name:"service_catalog"},
            },
            {
              text: "Espaces Verts",
              to: {name:"service_catalog.green_areas"},
            },
            {
              text: "Reception",
              active: true,
            },
          ],
          cols: [
            {
              name: 'N° ODS',
              field: 'ref',
              sort: ''
            },
            {
              name: 'Base de vie',
              field: 'lifebase',
              sort: ''
            },
            {
              name: 'Contrat',
              field: 'contract',
              sort: ''
            },
            {
              name: 'Prestataire',
              field: 'supplier',
              sort: ''
            },
            {
              name: 'Date ODS',
              field: 'date_ods',
              sort: ''
            },
            {
              name: 'Date Execution',
              field: 'date_exec_ods',
              sort: ''
            },
            {
              name: 'Coût ODS',
              field: 'total_amount',
              sort: ''
            },
            {
              name: 'Statut',
              field: 'status',
              sort: ''
            },
            {
              name: 'Réaliser',
              field: 'done',
              sort: ''
            },
            {
              name: 'Réception',
              field: 'read_at',
              sort: ''
            },
          ],
          selectedIndex:-1,
        };
      },
      mounted(){
        this.getODS();
      },
      watch:{
          selectedRows(){
            // console.log(this.selectedRows);
          },
          ODS:{
              handler(val){
                // console.log(val);
                if(val)this.refreshODS();
                
              }
          }
      },
      methods:{
        selectRow(row,index){
          if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
          else {
            this.selectedRows = [row];
            this.selectedIndex = index;
          }
        },
        getODS(){
          this.$http.post('/ods/OdsRestauration/listResto',{type:'green_space',done:false})
          .then(response => {
              // console.log(response.data);
              this.List_ODS = response.data;
          })
          .catch(error => console.log(error))
        },
        refreshODS(){
          this.getODS();
          this.selectedRows = [];
            this.selectedIndex = -1;
            this.ODS = false;
        }
      }
        
    }
    </script>
    <template>
        <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
          <div class="col-12">
            <div class="accordion mb-2" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                    <div class="row">
                      <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Ordres de Service (Espaces Verts)</div>
                      <div class="col text-right">
                        <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                        <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                      </div>
                    </div>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-0" :visible="active" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="shadow-lg">
                  <div class="table-responsive mb-0 shadow">
                    <dataset
                            v-slot="{ ds }"
                            :ds-data="List_ODS"
                          >
                            <div class="row">
                              <div class="col-md-6 mb-2 mb-md-0">
                                
                              </div>
                              <div class="col-md-3">
                                <!-- <dataset-search ds-search-placeholder="Search..." :wait="300" /> -->
                                <!-- <multiselect v-model="selectedCTT" :deselect-label="''" @select="getContractID" :select-label="''" :searchable="true" track-by="id" label="ref" :options="contracts" placeholder="Selectionner un contrat" :allow-empty="false">
                                  <template slot="singleContract" slot-scope="{ contract }">{{ contract.ref }}</template>
                                </multiselect> -->
                              </div>
                              <div class="col-md-3">
                                <!-- <dataset-search ds-search-placeholder="Search..." :wait="300" /> -->
                                <!-- <multiselect v-model="selectedBdv" :deselect-label="''" @select="getLifeBaseID" :select-label="''" placeholder="Selectionner une base de vie" :searchable="true" track-by="id" label="name" :options="bases" :allow-empty="false">
                                  <template slot="singleOds" slot-scope="{ base }">{{ base.name }}</template>
                                </multiselect> -->
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="">
                                  <table class="table table-hover d-md-table">
                                    <thead>
                                      <tr>
                                        <th v-for="(th) in cols" :key="th.field">
                                          {{ th.name }} 
                                        </th>
                                      </tr>
                                    </thead>
                                    <dataset-item tag="tbody">
                                      <template #default="{ row, rowIndex }">
                                        <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                          <th>
                                            {{ row.ref }}
                                          </th>
                                          <td>{{ row.lifebase.name }}</td>
                                          <td>{{ row.contract.ref }}</td>
                                          <td>{{ row.service_provider.prestname }}</td>
                                          <td>{{ row.date_ODS_format }}</td>
                                          <td>{{ row.date_exec_ODS_format }}</td>
                                          <td>{{ row.totalAmnt.withCurrency }}</td>
                                          <td v-html="row.status_label"></td>
                                          <td v-if="row.done == 1" class="text-center"><i class="fas fa-check-circle text-success"></i></td>
                                          <td v-else class="text-center"><i class="fas fa-times-circle text-danger"></i></td>
                                          <td v-if="row.read_at != null">{{ row.read_at }}</td>
                                          <td v-else class="text-center"><i class="fas fa-times-circle text-danger"></i></td>
                                        </tr>
                                      </template>
                                    </dataset-item>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                              <dataset-show :ds-show-entries="5" />
                              <dataset-pager />
                            </div>
                          </dataset>
                  </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
    
            <div class="card mt-3">
                <ODSform :ODS="selectedRows" @odsvalues="ODS = $event"/>
                <!-- <recep-form :ODS="selectedRows" @odsvalues="ODS = $event" /> -->
            </div>
          </div>
        </div>
        </Layout>
    </template>